<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder text-light mb-0">
          {{ userData.karyawan ? userData.karyawan.nama_lengkap : userData.email }}
        </p>
        <span class="user-status text-light"
          >{{ userData.level ? (userData.level.nama_level.toUpperCase() == "CABANG" ? "CABANG" : userData.level.nama_level.toUpperCase()) : "-" }}
          <!-- | {{ userData.karyawan ? userData.karyawan.email : '-' }} -->
        </span>
      </div>
      <b-avatar
        size="40"
        :src="userData.avatar"
        variant="white"
        badge
        class="badge-minimal"
        badge-variant="success"
        v-if="!myAvatarProfile"
      >
        <feather-icon
          v-if="!userData.karyawan"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
      <img
        v-else
        :src="myAvatarProfile"
        style="width: 30px"
        class="rounded"
        alt="profile"
      />
    </template>

    <b-dropdown-item v-if="isCabang"
    right 
    href="https://kasir.mybisnis.online"
    target="_blank" rel="noopener noreferrer"
    v-b-tooltip.hover title="Kasir"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="MonitorIcon" class="mr-50" />
      <span>Kasir</span>
    </b-dropdown-item>
    <b-dropdown-item
      :to="{ name: 'route-akun-pengguna' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon size="16" icon="UserIcon" class="mr-50" />
      <span>Pengguna</span>
    </b-dropdown-item>
    <b-dropdown-divider v-if="canSetting" />
    <b-dropdown-item
      v-if="canSetting"
      link-class="d-flex align-items-center"
      @click="$router.push('/setting')"
    >
      <feather-icon size="16" icon="SettingsIcon" class="mr-50" />
      <span>Pengaturan</span>
    </b-dropdown-item>
    <b-dropdown-divider />
    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,BTooltip,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,BTooltip,
  },
  computed: {
    myAvatarProfile() {
      // if (!this.user.karyawan || !this.user.karyawan.url_foto) {
      //   return false;
      // }

      // return this.user.karyawan.url_foto;
      if (!this.user || !this.user.url_foto) {
        return false;
      }

      return this.user.url_foto;
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
      canSetting: false,
    };
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      sessionStorage.clear();
      localStorage.clear();

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
    async checkCanSetting() {
      const settingMenu = await this.currentMenu("/setting");
      const params = {
        level_id: this.user.level ? this.user.level.id : null,
        menu_id: settingMenu ? settingMenu.id : null,
      };

      const status = await this.$store.dispatch("statusrole/getData", params);
      this.canSetting = status.some((st) => st.data_status == 1);
      sessionStorage.setItem("canSetting", this.canSetting);
    },
  },
  created() {
    this.checkCanSetting();
  },
};
</script>
